import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpEvent, HttpEventType } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class CountryService {

    countries = ['ae', 'ar', 'at', 'au', 'be', 'bg', 'br', 'ca', 'ch', 'cn', 'co', 'cu', 'cz', 'de', 'eg', 'fr', 'gb', 'gr', 'hk', 'hu', 'id', 'ie', 'il', 'in', 'it', 'jp', 'kr', 'lt', 'lv', 'ma', 'mx', 'my', 'ng', 'nl', 'no', 'nz', 'ph', 'pl', 'pt', 'ro', 'rs', 'ru', 'sa', 'se', 'sg', 'si', 'sk', 'th', 'tr', 'tw', 'ua', 'us', 've', 'za'];
    country = JSON.parse(localStorage.getItem('country'));
    countryList = JSON.parse(localStorage.getItem('countryList'));

    constructor(
        public httpClient: HttpClient,
    ) {

    }

    ipLookUp() {
        return new Promise((resolve, reject) => {
            this.getIp().then((ip) => {
                const req = new HttpRequest('GET', 'https://common-scripts.herokuapp.com/getMyIpData?ip=' + ip);
                this.httpClient.request(req).subscribe((event: HttpEvent<any>) => {
                    let res: HttpResponse<any>
                    if (event.type === HttpEventType.Response) {
                        res = event;
                        if (res) {
                            this.country = this.countryList.find((x) => { return x.alpha2Code == res['body'].data.countryCode })
                            if (this.country) {
                                localStorage.setItem('country', JSON.stringify(this.country));
                                resolve(this.country);
                            } else {
                                reject();
                            }
                        } else {
                            reject();
                        }
                    }
                })
            }).catch((err) => {
                resolve(err);
            })
        });
    }

    getIp() {
        return new Promise((resolve, reject) => {
            const req = new HttpRequest('GET', 'https://api.ipify.org?format=json');
            this.httpClient.request(req).subscribe((event: HttpEvent<any>) => {
                let res: HttpResponse<any>
                if (event.type === HttpEventType.Response) {
                    res = event;
                    if (res && res.status == 200) {
                        resolve(res.body.ip);
                    } else {
                        reject(res);
                    }
                }
            })
        });
    }

    // getCountriesData() {
    //     return new Promise((resolve, reject) => {
    //         const req = new HttpRequest('GET', 'https://restcountries.eu/rest/v2/all');
    //         this.httpClient.request(req).subscribe((event: HttpEvent<any>) => {
    //             let res: HttpResponse<any>
    //             if (event.type === HttpEventType.Response) {
    //                 res = event;
    //                 if (res && res.status == 200) {
    //                     this.countryList = res.body
    //                     localStorage.setItem('countryList', JSON.stringify(this.countryList));
    //                     resolve(this.countryList);
    //                 } else {
    //                     reject();
    //                 }
    //             }
    //         })
    //     });
    // }

    getCountriesData() {
        return this.httpClient.get("./assets/jsons/countries.json");
    }


    ipLookUpData(countryList) {
        return new Promise((resolve, reject) => {
            this.getIp().then((ip) => {
                const req = new HttpRequest('GET', 'https://common-scripts.herokuapp.com/getMyIpData?ip=' + ip);
                this.httpClient.request(req).subscribe((event: HttpEvent<any>) => {
                    let res: HttpResponse<any>
                    if (event.type === HttpEventType.Response) {
                        res = event;
                        if (res) {
                            let country = countryList.find((x) => { return x.alpha2Code == res['body'].data.countryCode })
                            if (country) {
                                resolve({ country: country, data: res['body'] });
                            } else {
                                reject();
                            }
                        } else {
                            reject();
                        }
                    }
                })
            }).catch((err) => {
                reject(err);
            })
        });
    }


}
