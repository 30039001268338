import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Rellax from 'rellax';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent {
  data;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
  ) {

    if (this.route.snapshot.data['data']) {
      this.data = this.route.snapshot.data['data'];
    }
  }

  // ngOnInit() {
  //   var rellaxHeader = new Rellax('.rellax-header');

  //   var body = document.getElementsByTagName('body')[0];
  //   body.classList.add('landing-page');
  //   var navbar = document.getElementsByTagName('nav')[0];
  //   navbar.classList.add('navbar-transparent');
  // }
  // ngOnDestroy() {
  //   var body = document.getElementsByTagName('body')[0];
  //   body.classList.remove('landing-page');
  //   var navbar = document.getElementsByTagName('nav')[0];
  //   navbar.classList.remove('navbar-transparent');
  // }
}
