import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from '../../services/firebase.service'
import { CSVService } from '../../services/csv.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent {
  id;
  visitors = []
  setting
  url = window.location
  fromDate;
  toDate;
  totalVisitors = 0;
  totalGoodVisitors = 0;
  totalBadVisitors = 0;
  visitorCols = ['Timestamp', 'IP', 'City', 'Region Name', 'Country', 'Lat', 'Lon', 'Zip', 'Divice Type'];
  limit = 10;
  page = 1;
  lastVisibleVisitor;
  visitorsLoading = false;
  constructor(
    public route: ActivatedRoute,
    public firebaseService: FirebaseService,
    public csvService: CSVService
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.firebaseService.db.collection("settings").doc(this.id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          let data = doc.data()
          data.id = doc.id
          this.setting = data
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
    this.getTotalVisitors();
    // this.getTotalGoodVisitors();
    // this.getTotalBadVisitors();
  }

  makeRedirectUrl(link) {
    console.log(link);
    if (link) {
      return 'redirection_url=' + link.replace(/^(https?:\/\/[^/]+)\/.*$/, '$1/');
    } else {
      return ''
    }
  }

  getTotalVisitors() {
    this.firebaseService.db.collection("visitors")
      .where("sid", "==", this.id)
      .where("urlType", "==", 'cloaked')
      .get()
      .then((querySnapshot) => {
        let totalVisitors = [];
        console.log(querySnapshot.size);
        this.totalVisitors = querySnapshot.size;
        querySnapshot.forEach((doc) => {
          console.log(doc.id, " => ", doc.data());
          let data = doc.data()
          data.id = doc.id
          totalVisitors.push(data)
        });
        setTimeout(() => {
          this.totalGoodVisitors = totalVisitors.filter(item => item.redirectionType === 'good').length;
          this.totalBadVisitors = totalVisitors.filter(item => item.redirectionType === 'bad').length;
        }, 2000);
      }).catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  getTotalGoodVisitors() {
    this.firebaseService.db.collection("visitors")
      .where("sid", "==", this.id)
      .where("redirectionType", "==", 'good')
      .where("urlType", "==", 'cloaked')
      .get()
      .then((querySnapshot) => {
        console.log(querySnapshot.size);
        this.totalGoodVisitors = querySnapshot.size;
      }).catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  getTotalBadVisitors() {
    this.firebaseService.db.collection("visitors")
      .where("sid", "==", this.id)
      .where("redirectionType", "==", 'bad')
      .where("urlType", "==", 'cloaked')
      .get()
      .then((querySnapshot) => {
        console.log(querySnapshot.size);
        this.totalBadVisitors = querySnapshot.size;
      }).catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  // getTotalVisitors() {
  //   this.firebaseService.db.collection("visitors")
  //     .where("sid", "==", this.id).get()
  //     .then((querySnapshot) => {
  //       console.log(querySnapshot.size);
  //       this.totalVisitors = querySnapshot.size;
  //     }).catch((error) => {
  //       console.log("Error getting documents: ", error);
  //     });
  // };

  getVisitors() {
    this.visitors = [];
    if (this.fromDate && this.toDate) {
      console.log(this.fromDate, this.toDate)
      let fromDate = new Date(this.fromDate.month + '-' + this.fromDate.day + '-' + this.fromDate.year);
      let toDate = new Date(this.toDate.month + '-' + this.toDate.day + '-' + this.toDate.year);
      console.log(fromDate, toDate)
      if (toDate < fromDate) {
        window.dispatchEvent(new CustomEvent('notification', {
          detail: {
            id: 1,
            type: 'danger',
            strong: 'Invalid To and From Dates!',
            message: `Please try again.`,
            icon: 'transportation_air-baloon'
          }
        }));
        return
      }
      this.visitorsLoading = true
      this.firebaseService.db.collection("visitors")
        .where("sid", "==", this.id)
        .where("timestamp", ">=", fromDate)
        .where("timestamp", "<=", toDate)
        .orderBy("timestamp")
        .limit(this.limit)
        .startAfter(this.lastVisibleVisitor ? this.lastVisibleVisitor : fromDate)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
            let data = doc.data()
            data.id = doc.id
            this.visitors.push(data)
          });
          this.lastVisibleVisitor = querySnapshot.docs[querySnapshot.docs.length - 1];
          this.visitorsLoading = false
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
          this.visitorsLoading = false
        });
    } else {
      window.dispatchEvent(new CustomEvent('notification', {
        detail: {
          id: 1,
          type: 'danger',
          strong: 'Invalid To and From Dates!',
          message: `Please try again.`,
          icon: 'transportation_air-baloon'
        }
      }));
    }
  }

  pageChange(e) {
    console.log(this.visitors);

    console.log(e)

  }

  nextPrevious() {
    this.page = this.page - 1
    console.log(this.page);
    this.getVisitors()
  }

  nextPage() {
    this.page = this.page + 1;
    console.log(this.page);
    this.getVisitors();
  }

  downloadAllVisitors() {
    this.downloadCSV()

  }

  downloadCSV() {
    let visitors = []
    if (this.fromDate && this.toDate) {
      console.log(this.fromDate, this.toDate)
      let fromDate = new Date(this.fromDate.month + '-' + this.fromDate.day + '-' + this.fromDate.year);
      let toDate = new Date(this.toDate.month + '-' + this.toDate.day + '-' + this.toDate.year);
      console.log(fromDate, toDate)
      if (toDate < fromDate) {
        window.dispatchEvent(new CustomEvent('notification', {
          detail: {
            id: 1,
            type: 'danger',
            strong: 'Invalid To and From Dates!',
            message: `Please try again.`,
            icon: 'transportation_air-baloon'
          }
        }));
        return
      }
      alert("Your Download Can Take Upto 15 Seconds To Gather Data. Please Wait...");
      this.visitorsLoading = true
      this.firebaseService.db.collection("visitors")
        .where("sid", "==", this.id)
        .where("timestamp", ">=", fromDate)
        .where("timestamp", "<=", toDate)
        .orderBy("timestamp")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log('===>1');
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " ===>1 ", doc.data());
            let data = doc.data()
            data.id = doc.id
            visitors.push(data)
          });
        }).then(() => {
          console.log('===>2', visitors);
          visitors.forEach(element => {
            element = Object.assign(element, element.ipData)
            element = Object.assign(element, element.deviceData)
            element = Object.assign(element, element.settings)
            if (element.timestamp) {
              element.timestamp = element.timestamp.toDate();
            }
            delete element.ipData;
            delete element.deviceData;
            delete element.settings;
          })
          console.log(visitors);
          this.csvService.downloadFile(visitors, 'Report -' + this.id + '-' + Date.now());
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
  }
}
