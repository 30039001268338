// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBI3QolHPryuPq8eiryzlZHpOOBJ1BJSPs",
    authDomain: "dynamicroutingprototype.firebaseapp.com",
    projectId: "dynamicroutingprototype",
    storageBucket: "dynamicroutingprototype.appspot.com",
    messagingSenderId: "239384377914",
    appId: "1:239384377914:web:806570341bad0de553d303",
    measurementId: "G-5SGMT6PRRG"
  }
};
