import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { LandingComponent } from './pages/landing/landing.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuardService } from "./services/auth-guard.service";
import { AdminDashboardComponent } from './pages/admin-dashboard/admin-dashboard.component';
import { ReportComponent } from './pages/report/report.component';

const routes: Routes = [
    { path: '', redirectTo: 'landing', pathMatch: 'full' },
    {
        path: 'component',
        component: ComponentsComponent,
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'landing',
        component: LandingComponent,
    },
    {
        path: 'admin-dashboard',
        component: AdminDashboardComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'report/:id',
        component: ReportComponent,
        canActivate: [AuthGuardService]
    }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
