import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
// import { NavController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: "root",
})
export class FirebaseService {
  firebaseui = window["firebaseui"];
  firebase = window["firebase"];
  db = this.firebase.firestore();
  ui = new this.firebaseui.auth.AuthUI(this.firebase.auth());

  constructor(
    public router: Router,
    // public toastController: ToastController,
  ) {
  }

  createYourLink() {
    if (this.firebase.auth().currentUser) {
      this.router.navigate(['/admin-dashboard'])
    } else {
      this.router.navigate(['/login'])
    }
  }

  loginWithFirebase() {

    let uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          return false;
        },
        uiShown: () => {
          // The widget is rendered.
          // Hide the loader.
          // setTimeout(() => {
          document.getElementById("ui-container").style.opacity = "1";
          // }, 500);
        },
      },
      // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
      // signInFlow: 'popup',
      // signInSuccessUrl: '<url-to-redirect-to-on-success>',
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        //   firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        //   firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        //   firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        //   firebase.auth.GithubAuthProvider.PROVIDER_ID,
        this.firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // this.firebase.auth.PhoneAuthProvider.PROVIDER_ID
      ],
      // Terms of service url.
      tosUrl: "<your-tos-url>",
      // Privacy policy url.
      privacyPolicyUrl: "<your-privacy-policy-url>",
    };

    this.firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log(user);
        localStorage.setItem('user', JSON.stringify(user));
        this.router.navigate(['/admin-dashboard'])
      } else {
        // No user is signed in.
        console.log("no user", user);
        localStorage.clear();
        this.ui.start("#firebaseui-auth-container", uiConfig);
      }
    });
  }

  getSettings(data) {
    return new Promise((resolve, reject) => {
      this.db.collection("settings").doc(data.id).get().then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          resolve(doc.data())
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          resolve(undefined);
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
        reject(error);
      });
    });
  }


  logout() {
    this.firebase.auth().signOut().then(() => {
      localStorage.clear();
      this.router.navigate(['/login'])
    }).catch((error) => {
      // An error happened.
      console.log(error)
    });
  }

  //   async presentToast(msg) {
  //     const toast = await this.toastController.create({
  //         message: msg,
  //         duration: 2000
  //     });
  //     toast.present();
  // }

}