import { Component, OnInit, Inject, Renderer2, ElementRef, ViewChild, AfterContentInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import { DOCUMENT } from '@angular/common';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';
import { NavbarComponent } from './shared/navbar/navbar.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentInit {
    alert;
    loading;
    private _router: Subscription;
    @ViewChild(NavbarComponent) navbar: NavbarComponent;

    constructor(private renderer: Renderer2, private cdRef: ChangeDetectorRef
        , private router: Router, @Inject(DOCUMENT,) private document: any, private element: ElementRef, public location: Location) {
        window.addEventListener("notification", data => {
            this.alert = data['detail']
            this.cdRef.detectChanges();

            setTimeout(() => {
                this.alert = undefined
                this.cdRef.detectChanges();

            }, 3000);
        });
        window.addEventListener("loading-run", data => {
            this.loading = data['detail']
            this.cdRef.detectChanges();
        });
        window.addEventListener("loading-stop", data => {
            this.loading = undefined
            this.cdRef.detectChanges();
        });
    }
    ngAfterContentInit() {

    }
    ngOnInit() {
        setTimeout(() => {
            var navbar: HTMLElement = this.element.nativeElement.children[0].children[0];
            this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
                if (window.outerWidth > 991) {
                    window.document.children[0].scrollTop = 0;
                } else {
                    window.document.activeElement.scrollTop = 0;
                }
                this.navbar.sidebarClose();

                this.renderer.listen('window', 'scroll', (event) => {
                    const number = window.scrollY;
                    var _location = this.location.path();
                    _location = _location.split('/')[2];

                    if (number > 150 || window.pageYOffset > 150) {
                        navbar.classList.remove('navbar-transparent');
                    } else if (this.location.path() !== '/admin-dashboard') {
                        // remove logic
                        if (navbar) {
                            navbar.classList.add('navbar-transparent');
                        }
                    }

                });
            });
            if (this.location.path() == '/admin-dashboard') {
                navbar.classList.remove('navbar-transparent');
            }
        }, 500);
    }

    closeAlert(alert) {
        this.alert = undefined;
    }
}
