import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FirebaseService } from "./firebase.service"

@Injectable()
export class AuthGuardService {


    constructor(
        public router: Router,
        public firebaseService: FirebaseService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('user')) {
            return true;
        } else {
            return false;
        }
    }
}