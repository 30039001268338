import { AfterViewInit, Component } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseService } from '../../services/firebase.service'
import { CountryService } from '../../services/country.service'
import { FormBuilder, Validators } from "@angular/forms";
import countriesAPI from "../../../../node_modules/countrycitystatejson";

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})

export class AdminDashboardComponent implements AfterViewInit {
  closeResult: string;
  user = JSON.parse(localStorage.getItem('user'));
  countryList;
  searchCountry;
  searchState;
  searchDestate;
  focus2;
  focus3;
  focus5;
  createLinkForm;
  AllSelectedStates = [];
  AllDeSelectedStates = [];
  AllSettings = [];
  url = window.location
  editingId;

  constructor(private modalService: NgbModal, public firebaseService: FirebaseService, public countryService: CountryService, public formBuilder: FormBuilder) {
    this.countryService.getCountriesData().subscribe(res => {
      if (res) {
        this.countryList = res;
        console.log('countries data: ', this.countryList);
      }
    })
    this.getAllSettings();
  }

  ngAfterViewInit(): void {
    window.dispatchEvent(new CustomEvent('notification', {
      detail: {
        id: 1,
        type: 'success',
        strong: 'Wellcome Back!',
        message: `${this.user.displayName}`,
        icon: 'emoticons_satisfied'
      }
    }));
  }



  create(content, type, modalDimension) {
    this.createLinkForm = this.formBuilder.group({
      name: [undefined],
      badLink: [undefined, [Validators.required, Validators.minLength(3)]],
      goodLink: [undefined, [Validators.required, Validators.minLength(3)]],
      selectedDomain: [this.url.host],
      selectedCountries: [[]],
      selectedStates: [[]],
      deselectedStates: [[]],
      selectedDevices: [[]],
      advanceBotProtection: [false],
      campaignStatus: [true],
      redirectionType: ['307'],
      days: [undefined],
      frequency: [undefined],
      selectedReferral: ['null'],
      referralName: [undefined],
      userId: [this.user.uid, [Validators.required]],
    })
    this.open(content, type, modalDimension)
  }

  edit(settings, content, type, modalDimension) {
    console.log(settings)
    this.editingId = settings.id
    this.createLinkForm = this.formBuilder.group({
      name: [settings.name],
      badLink: [settings.badLink, [Validators.required, Validators.minLength(3)]],
      goodLink: [settings.goodLink, [Validators.required, Validators.minLength(3)]],
      selectedDomain: [settings.selectedDomain],
      selectedCountries: [settings.selectedCountries],
      selectedStates: [settings.selectedStates],
      deselectedStates: [settings.deselectedStates],
      selectedDevices: [settings.selectedDevices],
      advanceBotProtection: [settings.advanceBotProtection],
      campaignStatus: [settings.campaignStatus],
      redirectionType: [settings.redirectionType],
      days: [settings.days],
      frequency: [settings.frequency],
      selectedReferral: [settings.selectedReferral],
      referralName: [settings.referralName],
      userId: [this.user.uid, [Validators.required]],
    })
    settings.selectedCountries.forEach(country => {
      countriesAPI.getStatesByShort(country.alpha2Code).forEach(element => {
        this.AllSelectedStates.push(element);
        this.AllDeSelectedStates.push(element);
      });
    });
    // if (settings.deselectedStates) {
    // settings.selectedCountries.forEach(country => {
    //   countriesAPI.getStatesByShort(country.alpha2Code).forEach(element => {
    //     this.AllDeSelectedStates.push(element);
    //   });
    // });
    // }
    setTimeout(() => {
      this.open(content, type, modalDimension)
    }, 100);
  }

  makeRedirectUrl(link) {
    if (link) {
      return 'redirection_url=' + link.replace(/^(https?:\/\/[^/]+)\/.*$/, '$1/');
    } else {
      return ''
    }
  }

  filter(country) {
    if (country && this.searchCountry) {
      return country.name.toLowerCase().includes(this.searchCountry.toLowerCase())
    } else {
      return true;
    }
  }

  findSelected(country) {
    return this.createLinkForm.get('selectedCountries').value.findIndex((x) => { return x.name == country.name }) != -1
  }

  selectCountry(country, event) {
    console.log(country)
    if (this.createLinkForm.get('selectedCountries').value.findIndex((x) => { return x.name.toLowerCase() == country.name.toLowerCase() }) == -1) {
      this.searchCountry = '';
      countriesAPI.getStatesByShort(country.alpha2Code).forEach(element => {
        console.log(element)
        this.AllSelectedStates.push(element);
        this.AllDeSelectedStates.push(element);
      });
      let x = this.createLinkForm.get('selectedCountries').value;
      x.push(country);
      this.createLinkForm.get('selectedCountries').setValue(x);
    }
    console.log(this.AllSelectedStates);
    console.log(this.AllDeSelectedStates);
  }

  removeCountry(i): void {
    let y = countriesAPI.getStatesByShort(this.createLinkForm.get('selectedCountries').value[i].alpha2Code)
    this.createLinkForm.get('selectedStates').setValue([]);
    this.createLinkForm.get('deselectedStates').setValue([]);
    y.forEach(e => {
      if (this.AllSelectedStates.includes(e)) {
        this.AllSelectedStates.splice(this.AllSelectedStates.indexOf(e), 1)
      }
      if (this.AllDeSelectedStates.includes(e)) {
        this.AllDeSelectedStates.splice(this.AllDeSelectedStates.indexOf(e), 1)
      }
    });
    let x = this.createLinkForm.get('selectedCountries').value;
    x.splice(i, 1)
    this.createLinkForm.get('selectedCountries').setValue(x);
    console.log(this.AllSelectedStates);
    console.log(this.AllDeSelectedStates);
  }

  private getDismissReason(reason: any): string {
    this.editingId = undefined;
    this.AllSelectedStates = [];
    this.AllDeSelectedStates = [];
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  filterState(state) {
    if (state && this.searchState) {
      return state.toLowerCase().includes(this.searchState.toLowerCase())
    } else {
      return true;
    }
  }

  findSelectedState(state) {
    return this.createLinkForm.get('selectedStates').value.includes(state)
  }

  selectState(state, event) {
    if (!this.createLinkForm.get('selectedStates').value.includes(state)) {
      this.searchState = '';
      let x = this.createLinkForm.get('selectedStates').value;
      x.push(state);
      this.createLinkForm.get('selectedStates').setValue(x);
    }
  }

  removeState(i): void {
    let x = this.createLinkForm.get('selectedStates').value;
    x.splice(i, 1)
    this.createLinkForm.get('selectedStates').setValue(x);

  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  filterDestate(state) {
    if (state && this.searchDestate) {
      return state.toLowerCase().includes(this.searchDestate.toLowerCase())
    } else {
      return true;
    }
  }

  findDeselectedState(state) {
    return this.createLinkForm.get('deselectedStates').value == null ? false : this.createLinkForm.get('deselectedStates').value.includes(state)
  }

  deselectState(state, event) {
    if (this.createLinkForm.get('deselectedStates').value == null) {
      this.createLinkForm.get('deselectedStates').setValue([state]);
    }
    if (!this.createLinkForm.get('deselectedStates').value.includes(state)) {
      this.searchDestate = '';
      let x = this.createLinkForm.get('deselectedStates').value;
      x.push(state);
      this.createLinkForm.get('deselectedStates').setValue(x);
    }
  }

  removeDestate(i): void {
    let x = this.createLinkForm.get('deselectedStates').value;
    x.splice(i, 1)
    this.createLinkForm.get('deselectedStates').setValue(x);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  findSelectedDevice(device) {
    return this.createLinkForm.get('selectedDevices').value.includes(device)
  }

  selectDevice(device, event) {
    if (!this.createLinkForm.get('selectedDevices').value.includes(device)) {
      let x = this.createLinkForm.get('selectedDevices').value;
      x.push(device);
      this.createLinkForm.get('selectedDevices').setValue(x);
    }
  }

  removeDevice(i): void {
    let x = this.createLinkForm.get('selectedDevices').value;
    x.splice(i, 1)
    this.createLinkForm.get('selectedDevices').setValue(x);

  }

  createLink() {
    console.log(this.createLinkForm);
    if (this.createLinkForm.valid) {
      let setting = this.createLinkForm.getRawValue()
      this.firebaseService.db.collection("settings").add(setting)
        .then((docRef) => {
          if (docRef.id) {
            document.getElementById('close-add-link-btn').click();
            window.dispatchEvent(new CustomEvent('notification', {
              detail: {
                id: 1,
                type: 'success',
                strong: 'Link Created!',
                message: ``,
                icon: 'media-2_note-03'
              }
            }));
            this.getAllSettings()
          }
        });
    } else {
      setTimeout(() => { }, 100);
      window.dispatchEvent(new CustomEvent('notification', {
        detail: {
          id: 1,
          type: 'danger',
          strong: 'Invalid Form!',
          message: `Please try again.`,
          icon: 'transportation_air-baloon'
        }
      }));
    }
  }

  saveLink() {
    console.log(this.createLinkForm);
    if (this.createLinkForm.valid) {
      let setting = this.createLinkForm.getRawValue()
      this.firebaseService.db.collection("settings").doc(this.editingId).update(setting)
        .then((docRef) => {
          document.getElementById('close-add-link-btn').click();
          window.dispatchEvent(new CustomEvent('notification', {
            detail: {
              id: 1,
              type: 'success',
              strong: 'Link Edited Successfully!',
              message: ``,
              icon: 'media-2_note-03'
            }
          }));
          this.getAllSettings()
        });
    } else {
      // setTimeout(() => { }, 100);
      window.dispatchEvent(new CustomEvent('notification', {
        detail: {
          id: 1,
          type: 'danger',
          strong: 'Invalid Form!',
          message: `Please try again.`,
          icon: 'transportation_air-baloon'
        }
      }));
    }
  }

  fadeOptions(e) {
    setTimeout(() => {
      this[e] = false
    }, 100);
  }

  getAllSettings() {
    this.AllSettings = []
    this.firebaseService.db.collection("settings").where("userId", "==", this.user.uid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          let data = doc.data()
          data.id = doc.id
          this.AllSettings.push(data)
          console.log(this.AllSettings)
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

  }


  open(content, type, modalDimension) {
    if (modalDimension === 'sm' && type === 'modal_mini') {
      this.modalService.open(content, { windowClass: 'modal-mini modal-primary', size: 'sm' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } else if (modalDimension == undefined && type === 'Login') {
      this.modalService.open(content, { windowClass: 'modal-login modal-primary' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } else {
      this.modalService.open(content).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }
}
