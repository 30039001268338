import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FirebaseService } from '../../services/firebase.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

    data: Date = new Date();
    focus;
    focus1;

    constructor(
        public firebaseService: FirebaseService
    ) {
    }

    ngOnInit() {
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('login-page');

        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
    }
    ngAfterViewInit() {
        console.log(document.getElementById("ui-container"));
        this.firebaseService.loginWithFirebase()
    }
    ngOnDestroy() {
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('login-page');

        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
    }

}
